<template>
  <div class="table-content">
    <div class="table-title">
      <div class="title-nav">
        <el-button
          v-for="(item, index) in btnList"
          :key="index"
          class="title-btn"
          type="primary"
          :plain="item.type"
          @click="handTitle(index)"
          >{{ item.name }}</el-button
        >
      </div>
      <el-button type="primary">添 加</el-button>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      border
      tooltip-effect="dark"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center">
      </el-table-column>
      <el-table-column prop="version" label="版本" width="200" align="center">
      </el-table-column>
      <el-table-column prop="type" label="状态" width="200" align="center">
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="创建时间"
        width="350"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="updated_at"
        label="更新时间"
        width="350"
        align="center"
      >
      </el-table-column>
      <el-table-column label="操作" show-overflow-tooltip align="center">
        <template #default="scope">
          <el-button @click="handleClick(scope.$index)" type="text" size="small"
            >修改信息</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="table-bottom">
      <el-button
        class="delete-btn"
        type="primary"
        :disabled="disabled"
        @click="handDeleteClient"
        >删 除</el-button
      >
      <Pagination
        :pageSize="pageSize"
        :currentPage="currentPage"
        :total="total"
        @handSizeChange="handSizeChange"
        @handCurrentChange="handCurrentChange"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination.vue";
import {
  clientversionList,
  clientversionDelete,
} from "@/api/apiList/system-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      btnList: [
        {
          id: 2,
          name: "iOS",
          type: false,
        },
        {
          id: 3,
          name: "Android",
          type: true,
        },
      ],
      page: 1,
      pageSize: 10,
      tableData: [],
      loading: true,
      multipleSelection: [],
      currentPage: 1,
      total: 10,
      disabled: true,
      titleId: 2,
    };
  },
  methods: {
    handTitle(e) {
      this.btnList.forEach((item) => {
        item.type = true;
      });
      this.btnList[e].type = false;
      this.titleId = this.btnList[e].id;
      this.page = 1;
      this.handList(this.titleId);
    },
    handleSelectionChange(e) {
      if (e.length > 0) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
      this.multipleSelection = e.map((item) => item.id);
    },
    handDeleteClient() {
      let obj = {
        id: this.multipleSelection.toString(),
      };
      this.$confirm("确定要删除此客户端版本吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        clientversionDelete(obj).then((res) => {
          if (res.result === "200") {
            this.$message.success({
              message: res.data.info,
              type: "success",
            });
            this.handList(this.titleId);
          }
        });
      });
    },
    handSizeChange(e) {
      this.loading = true;
      this.pageSize = e;
      this.handList(this.titleId);
    },
    handCurrentChange(e) {
      this.loading = true;
      this.currentPage = e;
      this.page = e;
      this.handList(this.titleId);
    },
    handList(e) {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,
        client: e,
      };
      clientversionList(obj).then((res) => {
        if (res.result === "200") {
          res.data.data.forEach((item) => {
            switch (item.type) {
              case 0:
                item.type = "上线版本";
                break;
              case 1:
                item.type = "送审版本";
                break;
              case 2:
                item.type = "测试版本";
                break;
            }
          });
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.handList(this.titleId);
  },
};
</script>

<style lang="scss">
.title-nav {
  display: flex;

  .title-btn {
    margin-right: 10px;
  }
}
</style>
